<template>
  <div class="main-wrap">
    <h4 class="title-h4">我的优惠券</h4>
    <div class="tabs">
      <p
        class="tabItem"
        @click="tabChange(item.code)"
        :class="{ active: item.code === tabCode }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div>
    <div class="couponList">
      <div class="couponItem" :class="item.state == 4?'used':item.state == 3?'overdue':''" v-for="(item,index) in couponList" :key="index">
        <!-- <p class="price">￥<span>{{item.couponPrice}}</span></p>
        <p class="condition">{{item.condition}}</p> -->
        <div :class="{'origin-layer' : tabCode == 1}">
          <template v-if="item.type == 1">
            <p class="price">¥<span
              :style="[
                {
                  fontSize:(item.couponPrice+'').length < 4?'64px':(item.couponPrice+'').length == 4?'55px':'40px'
                },
                {
                  lineHeight:(item.couponPrice+'').length <= 4?'77px':'77px'
                }
              ]"
            >{{item.couponPrice}}</span></p>
            <p class="condition">满{{item.fullPrice}}元可用</p>
          </template>
          <template v-if="item.type == 3">
            <p class="price"><span>{{item.discount}}</span>折</p>
            <p class="condition">满{{item.discountPrice}}{{item.discountType==1?'件':'元'}}可用</p>
          </template>
          <p class="type">「{{item.receiveDesc}}」</p>
          <p class="time" v-if="item.times == 2 || item.times == 3">有效期至{{item.lapseTime}}</p>
          <p class="time" v-if="item.times == 1">不限使用时间</p>
          <!-- <p class="available" v-if="tabCode == 1" @click="goCouponDetail(item)">可用商品</p> -->
          <img class="icon" v-if="item.state == 3" src="@/assets/image/order/overdueIcon.png" alt="">
          <img class="icon" v-if="item.state == 4" src="@/assets/image/order/usedIcon.png" alt="">
        </div>
        <div class="mask-layer" v-if="tabCode == 1">
          <span class="btn" @click="goCouponDetail(item)">可用商品</span>
        </div>
      </div>
    </div>
    <a-spin class="load" v-show="loading" size='large' />
    <!-- 暂无课程 -->
    <no-message :msg="'暂无优惠券'" v-if="!loading && !couponList.length" :imgSrc="require('@/assets/image/personalCenter/noCoupon.png')" />
    <a-pagination
      :hideOnSinglePage="true"
      :pageSize="pageSize"
      :default-current="6"
      :total="total"
      @change="onChange"
    />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      clientWidth: 0,
      pageNum: 1,
      pageSize: 2,
      total: 0,
      tabMenu: [
        {
					code: 1,
					name: '未使用',
				},
				{
					code: 2,
					name: '已使用',
				},
				{
					code: 3,
					name: '已过期',
				}
      ], // 二级分类
      tabCode: 1,
      loading: false,
      // 优惠券列表
      couponList: [],
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onChange(e) {
      console.log(e)
      this.pageNum = e;
      this.getCouponList();
    },
    // tab切换
    tabChange(e) {
      this.tabCode = e;
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getCouponList();
    },
    goShop() {
      this.$router.push("/knowledgeMall/shopHome");
    },
    // 跳转优惠券详情
    goCouponDetail(e) {
      if(e.scope==1){
        this.$router.push('/knowledgeMall/shopHome');
      }else{
        this.$router.push({
          name: 'couponDetail',
          query: {
            couponId: this.$AES.encode_data(e.couponId + ""),
            name: '优惠券详情'
          },
          params: {
            couponDetail: e
          }
        });
      }
    },
    // 获取优惠券列表
    getCouponList() {
      this.loading = true
      this.$ajax({
        url:'/hxclass-pc/pc-mall/list',
        method:'get',
        params:{
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          state: this.tabCode
        }
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.couponList = res.data.records;
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    if(this.clientWidth >= 16000){
      this.pageSize = 8;
    }else{
      this.pageSize = 6;
    }
    this.getCouponList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        // console.log(document.body.clientWidth);
        if(window.screenWidth >= 16000){
          that.pageSize = 8;
        }else{
          that.pageSize = 6;
        }
        that.clientWidth = window.screenWidth;
      })()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.main-wrap{
  position: relative;
  min-height: 100%;
}
/deep/.ant-pagination{
  text-align: center;
}
.noMessage{
  padding-top: 184px;
}
.tabs {
  display: inline-block;
  position: relative;
  left: -40px;
  padding: 0 40px;
  margin-top: 22px;
  background-color: rgba(21, 183, 221, 0.1);
  .tabItem {
    display: inline-block;
    padding: 0 38px;
    line-height: 38px;
    font-size: 18px;
    color: #666666;
    margin-right: 20px;
    cursor: pointer;
  }
  .tabItem:last-child {
    margin: 0;
  }
  .active {
    color: #ffffff;
    background-color: #15b7dd;
  }
}
.available{
  cursor: pointer;
  margin: 0 auto;
  margin-top: 12px;
  width: 94px;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  border: 1px solid rgba(124,46,12,0.5000);
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #4D1D08;
}
.available:hover{
  background: #6B6562;
  color: #FFFFFF;
}

.couponList{
  display: flex;
  flex-wrap: wrap;
  .couponItem{
    width: 180px;
    height: 260px;
    margin-right: 49px;
    background-image: url("~@/assets/image/order/my.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 22px;
    margin-bottom: 18px;
    overflow: hidden;
    position: relative;
    &:hover{
      .origin-layer{
        filter: blur(5px);
      }
      .mask-layer{
        top: 0;
      }
    }
    .origin-layer{
      transition: all .5s;
    }
    
    .price{
      height: 82px;
      font-size: 30px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: #38312E;
      line-height: 81px;
      // text-shadow: 0px 0px -1px #000000;
      span{
        font-size: 68px;
      }
    }
    .condition{
      margin-top: 11px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FB951A;
      line-height: 18px;
    }
    .type{
      padding: 0 4px;
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1C1714;
      line-height: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }
    .time{
      margin-top: 10px;
      font-size: 10px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #A97D52;
      line-height: 10px;
    }
    img{
      position: absolute;
      right: 0;
      bottom: 11px;
      width: 61px;
      height: 64px;
    }

    .mask-layer{
      position: absolute;
      height: 100%;
      width: 100%;
      transition: all .4s;
      background-image: url("~@/assets/image/order/20240715-142413.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      left: 0;
      top: 100%;
      .btn{
        display: inline-block;
        text-align: center;
        margin-top: 100px;
        width: 118px;
        height: 35px;
        line-height: 35px;
        color: #ffffff;
        background: #C17E2D;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
  .overdue{
    background-image: url("~@/assets/image/order/overdue.png");
    .price,
    .condition,
    .type,
    .time{
      opacity: 0.5;
    }
  }
  .used{
    background-image: url("~@/assets/image/order/used.png");
    .price,
    .condition,
    .type,
    .time{
      opacity: 0.5;
    }
    .price{
      color: #ffffff;
    }
    .time{
      color: #968A7E;
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .main-box .tabs {
    padding: 0 30px;
    .tabItem{
      padding: 0 25px;
    }
  }
  .title-h4{
    font-size: 22px !important;
  }
  .couponList .couponItem{
    width: 165px;
    height: 238px;
    padding-top: 14px;
    .price{
      height: 77px;
      font-size: 28px;
      line-height: 77px;
      span{
        font-size: 64px;
      }
    }
    .condition{
      margin-top: 13px;
    }
  }
}

.load{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
